// 1. React Imports:
import { useEffect, useState } from "react";

// 2. Third-Party Library Imports:
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";

// 6. Utility Functions or Constants:
import { req } from "utils/api";

// 7. Assets:
import AddIcon from "@mui/icons-material/Add";
import LocationOnIcon from "@mui/icons-material/LocationOn";

export function LocationManagement({
  account_id,
  account_locations,
  addLocation,
  removeLocation,
  updateLocations,
}) {
  /*-------------------------------------
    State Variables and Hooks
  -------------------------------------*/
  const [locations, setLocations] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [showAddLocation, setShowAddLocation] = useState(false);
  const [searchLocations, setSearchLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [openRelationshipDialog, setOpenRelationshipDialog] = useState(false);
  const [selectedRelationship, setSelectedRelationship] = useState("");

  /*-------------------------------------
      Derived Data and Functions
  -------------------------------------*/

  const getChipColor = (relationship) => {
    switch (relationship) {
      case "Site Contact":
        return "#FF8A80"; // Lighter red
      case "Property Manager":
        return "#B9F6CA"; // Lighter green
      case "LGA Contact":
        return "#82B1FF"; // Lighter blue
      case "Redemption Partner":
        return "#FFD180"; // Lighter orange
      default:
        return "#E0E0E0"; // Light grey (default)
    }
  };

  /*-------------------------------------
		Event Handlers
	-------------------------------------*/

  const handleToggleAddLocation = () => {
    setShowAddLocation((prevState) => !prevState);
  };

  const handleLocationSearch = async (searchTerm) => {
    if (searchTerm) {
      setSearchLocations(
        allLocations.filter(
          (location) =>
            (location.location_name
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
              location.location_g_id
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) &&
            !locations.some(
              (existingLocation) =>
                existingLocation.location_id === location.location_id
            )
        )
      );
    } else {
      setSearchLocations([]);
    }
  };

  const handleLocationSelect = (event, newValue) => {
    setSelectedLocation(newValue);
    if (newValue) {
      setOpenRelationshipDialog(true);
    }
  };

  const handleAddLocation = async () => {
    if (!selectedLocation || !selectedRelationship) {
      return false;
    }

    const postData = {
      account_id: account_id,
      location_id: selectedLocation.location_id,
      relationship: selectedRelationship,
    };

    const response = await addLocation(postData);

    if (response) {
      const result = response.newRecord;
      const newLocation = {
        account_location_id: result.account_location_id,
        location_id: result.location_id,
        location_name: result.location_name,
        location_relationship: result.location_relationship,
      };

      // Update the state with the new location
      const updatedLocations = [...locations, newLocation];
      updateLocations(updatedLocations);

      setOpenRelationshipDialog(false);
      setSelectedLocation(null);
      setSelectedRelationship("");
      handleToggleAddLocation();
    }
  };

  const handleClickRemoveLocation = async (deleteLocation) => {
    const postData = {
      account_id: account_id,
      location_id: deleteLocation.location_id,
    };

    const response = await removeLocation(postData);

    if (response) {
      updateLocations(
        locations.filter(
          (location) =>
            location.account_location_id !== deleteLocation.account_location_id
        )
      );
    }
  };

  /*-------------------------------------
		Effects
	-------------------------------------*/

  useEffect(() => {
    setLocations(account_locations);
    getLocations();
  }, [account_locations]);

  /*-------------------------------------
            Async Functions
  -------------------------------------*/

  const getLocations = async () => {
    const response = await req("get", "admin/get_locations?all=yes");
    if (response.success) {
      setAllLocations(response.data);
    }
  };

  return (
    <>
      <div className="mx-auto flex w-full max-w-3xl items-center justify-between pt-10">
        <div className="text-2xl font-bold">Location Management</div>
        <div className="flex items-center ml-auto">
          <Button
            className="mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base"
            onClick={handleToggleAddLocation}
            startIcon={showAddLocation ? null : <AddIcon fontSize="small" />}
          >
            {showAddLocation ? "Cancel" : "Add Location"}
          </Button>
        </div>
      </div>
      {showAddLocation && (
        <div
          id="linkLocation"
          className="mx-auto flex w-full flex-col max-w-3xl items-end justify-between mt-3"
        >
          <div className="w-full">
            <div className="text-lg">Add a new Location</div>
          </div>
          <div className="mt-2 flex flex-row w-full">
            <Autocomplete
              options={searchLocations}
              getOptionLabel={(option) => option.location_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search locations"
                  variant="outlined"
                />
              )}
              onInputChange={(event, newInputValue) =>
                handleLocationSearch(newInputValue)
              }
              onChange={handleLocationSelect}
              fullWidth
            />
          </div>
        </div>
      )}
      {locations.length > 0 && (
        <div className="mt-4 flex flex-row flex-wrap gap-2">
          {locations.map((location) => {
            const chipColor = getChipColor(location.location_relationship);
            return (
              <Chip
                key={location.location_id}
                icon={<LocationOnIcon />}
                variant="outlined"
                label={`${location.location_name} (${location.location_relationship})`}
                onDelete={() => handleClickRemoveLocation(location)}
                sx={{
                  backgroundColor: chipColor,
                  color: "#000000",
                  "& .MuiChip-deleteIcon": {
                    color: "#000000",
                    "&:hover": { color: "#FF0000" },
                  },
                  fontWeight: 500,
                  padding: "4px 0",
                }}
              />
            );
          })}
        </div>
      )}
      <Dialog
        open={openRelationshipDialog}
        onClose={() => setOpenRelationshipDialog(false)}
      >
        <DialogTitle>Select Relationship Type</DialogTitle>
        <DialogContent>
          <RadioGroup
            value={selectedRelationship}
            onChange={(e) => setSelectedRelationship(e.target.value)}
          >
            {[
              "Site Contact",
              "Property Manager",
              "LGA Contact",
              "Redemption Partner",
            ].map((type) => (
              <FormControlLabel
                key={type}
                value={type}
                control={<Radio />}
                label={type}
              />
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRelationshipDialog(false)}>
            Cancel
          </Button>
          <Button onClick={handleAddLocation} variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
