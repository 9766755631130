import { useState, useEffect, useCallback } from 'react';
import { GoogleMap, LoadScript, Marker, MarkerClusterer, InfoWindow } from '@react-google-maps/api';
import { req } from 'utils/api';
import { DateRangePicker } from 'react-date-range';
import { addDays, format } from 'date-fns';
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { debounce } from 'lodash'; // Ensure lodash is installed

const mapContainerStyle = {
  width: '100%',
  height: '600px', // Increased height for better visibility
};

// Default center set to Melbourne
const defaultCenter = { lat: -37.8136, lng: 144.9631 };

export function AdminAnalyticsMaps() {
  const [map, setMap] = useState(null);
  const [startDate, setStartDate] = useState(addDays(new Date(), -30));
  const [endDate, setEndDate] = useState(new Date());
  const [anchorEl, setAnchorEl] = useState(null);
  const [deposits, setDeposits] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null); // For InfoWindow
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch deposits data based on date range
  const getDeposits = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    const startDateString = format(startDate, 'yyyy-MM-dd');
    const endDateString = format(endDate, 'yyyy-MM-dd');
    const url = `get_map_deposits?startDate=${startDateString}&endDate=${endDateString}`;

    try {
      const res = await req('GET', url);
      if (res.success) {
        setDeposits(res.data);
        setIsDataLoaded(true);
      } else {
        setError(res.message || 'Failed to fetch data');
      }
    } catch (err) {
      setError(err.message || 'An error occurred');
    }
    setIsLoading(false);
  }, [startDate, endDate]);

  // Handle date range selection
  const handleSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    setStartDate(new Date(startDate));
    setEndDate(new Date(endDate));
    if (startDate && endDate && startDate !== endDate) {
      setAnchorEl(null);
    }
  };

  // Handle popover open/close
  const handleOpenPopover = (event) => {
    //only once per click
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? 'date-range-popover' : undefined;

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
  };

  useEffect(() => {
    getDeposits();
  }, [getDeposits]);

  return (
    <div className="flex flex-col flex-auto">
      <div className="p-6">
        <h1 className="text-2xl font-bold mb-4">Analytics: Interactive Maps</h1>
        <Box className="w-full relative" sx={{ p: 0 }}>
          <Button variant="text" fullWidth onClick={handleOpenPopover}>
            {`${format(startDate, 'MMM dd, yyyy')} - ${format(endDate, 'MMM dd, yyyy')}`}
          </Button>
          <Popover
            id={popoverId}
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={handleSelect}
              moveRangeOnFirstSelection={false}
            />
          </Popover>
        </Box>

        {/* Loading and Error Messages */}
        {isLoading && <div className="loader">Loading...</div>}
        {error && <div className="error text-red-500">{error}</div>}
        {!isLoading && !error && isDataLoaded && deposits.length === 0 && (
          <div className="text-center py-4 text-gray-600">
            No deposits found for the selected date range.
          </div>
        )}
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={defaultCenter}
            zoom={7}
            onLoad={(mapInstance) => setMap(mapInstance)}
            onUnmount={() => setMap(null)}
            options={{
              // Enable zoom controls
              zoomControl: true,
              // Other options can be added as needed
            }}
          >
            {isDataLoaded && (
              <MarkerClusterer>
                {(clusterer) => (
                  <>
                    {deposits.map((data) => {
                      const depositsCount = parseInt(data.total_deposits);
                      // Determine color based on deposits relative to max
                      const maxDeposits = Math.max(...deposits.map(d => parseInt(d.total_deposits)));
                      let color = '#00FF00'; // Green
                      if (depositsCount > 0.66 * maxDeposits) {
                        color = '#FF0000'; // Red
                      } else if (depositsCount > 0.33 * maxDeposits) {
                        color = '#FFA500'; // Orange
                      }

                      return (
                        <Marker
                          key={data.location_g_id}
                          position={{ lat: parseFloat(data.latitude), lng: parseFloat(data.longitude) }}
                          clusterer={clusterer}
                          onClick={() => setSelectedMarker(data)}
                          icon={{
                            path: window.google.maps.SymbolPath.CIRCLE,
                            scale: 50 , // Adjust scaling factor as needed
                            fillColor: color,
                            fillOpacity: 0.8,
                            strokeColor: '#FFFFFF',
                            strokeWeight: 1,
                          }}
                        />
                      );
                    })}

                    {/* Console log and InfoWindow for selected marker */}
                    {selectedMarker && console.log('Selected Marker:', selectedMarker)}
                    {(selectedMarker && selectedMarker.total_deposits > 0 && selectedMarker.latitude !== 0 && selectedMarker.longitude !== 0) && (
                      <InfoWindow
                        position={{ lat: parseFloat(selectedMarker.latitude), lng: parseFloat(selectedMarker.longitude) }}
                        onCloseClick={() => setSelectedMarker(null)}
                      >
                        <div>
                          <h2 className="font-bold">{selectedMarker.location_name}</h2>
                          <p><strong>Deposits:</strong> {parseInt(selectedMarker.total_deposits).toLocaleString()}</p>
                          <p><strong>ID:</strong> {selectedMarker.location_g_id}</p>
                        </div>
                      </InfoWindow>
                    )}
                  </>
                )}
              </MarkerClusterer>
            )}
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  );
}
